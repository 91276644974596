const $siteHeader = document.getElementById('site-header');
const $svgLogo = document.getElementById('ESS-svg-site-name');

window.addEventListener('scroll', function () {
    if (window.scrollY < 10) {
        $siteHeader.classList.remove('active');
        $svgLogo.style.opacity = 1;
    } else {
        $siteHeader.classList.add('active');
        $svgLogo.style.opacity = 0;
    }
});

const $hamburger = document.getElementById('hamburger');
$hamburger.addEventListener('click', function () {
    this.classList.toggle('is-active');
    $siteHeader.classList.toggle('is-active');
    document.body.classList.toggle('hamburger-active');
});


// PROFILE BLOCKS SECTION HEIGHT = CONTENT HEIGHT
// if (
//     document.body.classList.contains('profile-page') ||
//     document.body.classList.contains('home')) {
//     const $profilesBlock = document.querySelector('.profiles-block');
//     const $profilesContent = document.querySelector('.profiles-grid');
//     $profilesBlock.style.height = $profilesContent.offsetHeight - 250 + 'px';
// }
